import React from "react";
import Icon from "./Icon";
import Link from "next/link";

interface Props {
  page?: string;
  category: string | string[] | undefined;
  product?: string;
  notCategory: boolean;
}

export default function BreadCrumbs({
  page = "home",
  category,
  product,
  notCategory = true,
}: Props) {
  return (
    <div className="breadcrumbs flex items-center gap-2">
      <Link
        href={"/"}
        className={`breadcrumb text-secondary-text-color font-medium text-[13px] capitalize ${
          category ? "" : "selected-breadcrumb"
        }`}
      >
        {page}
      </Link>
      {category && <Icon iconName="i-chevronright-icon" size="text-[10px]" />}
      {notCategory ? (
        <p
          className={`breadcrumb-not_category text-secondary-text-color font-medium text-[13px] capitalize ${
            category && "selected-breadcrumb"
          }`}
        >
          {category}
        </p>
      ) : (
        <Link
          href={`${category === "all products" ? "/category" : `/category/${category}`}`}
          className={`breadcrumb text-secondary-text-color font-medium text-[13px] capitalize ${
            product ? "" : "selected-breadcrumb"
          }`}
        >
          {category}
        </Link>
      )}
      {product && <Icon iconName="i-chevronright-icon" size="text-[10px]" />}
      <p
        className={`breadcrumb text-secondary-text-color font-medium text-[13px] capitalize ${
          product && "selected-breadcrumb"
        }`}
      >
        {product}
      </p>
    </div>
  );
}
