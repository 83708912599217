import React from "react";
import { FieldErrors } from "react-hook-form";

interface Props {
  errors: FieldErrors<any>;
  inputName: string;
}

export default function Errors({ errors, inputName }: Props) {
  return (
    <p className="absolute text-red-hot text-sm top-[-10px] left-0">
      {errors && errors[inputName] && (errors[inputName]!.message! as string)}
    </p>
  );
}
