import React, { useId } from "react";
import { FieldErrors, UseFormRegisterReturn, UseFormWatch } from "react-hook-form";
import Errors from "./Errors";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  type: "text" | "email" | "password" | "tel" | "number";
  label?: string;
  className?: string;
  register: UseFormRegisterReturn<any>;
  errors: FieldErrors<any>;
  watch: UseFormWatch<any>;
}

export default function FormInput({
  type,
  label,
  className,
  register,
  errors,
  watch,
  ...rest
}: Props) {
  const id = useId();

  const inputName = register.name;

  const inputWatch = watch(inputName);

  let hasError = false;

  if (errors && errors[inputName]) {
    hasError = true;
  }

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="login_form_name-label text-xs uppercase font-semibold text-placeholder-text-color tracking-[1px]"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        {...register}
        className={`${className} caret-secondary-text-color text-secondary-text-color outline-none border border-disable-color max-w-[440px] w-full px-[15px] h-[46px] ${
          hasError ? "border-red-hot" : "border-disable-color"
        }`}
        {...rest}
      />
      <div className="relative">
        <Errors errors={errors} inputName={inputName} />
      </div>
      {/* {!hasError && inputWatch && (
        <Icon
          iconName="i-validated-icon"
          size="text-[18px]"
          className="absolute top-[40px] right-[15px]"
        />
      )} */}
    </>
  );
}
