import Link from "next/link";
import Picture from "@/components/common/picture/Picture";
import Icon from "@/components/common/ui/Icon";
import { useForm } from "react-hook-form";
import FormInput from "@/components/common/ui/FormInput";
import { useAuth } from "@/store/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { loginApiCall } from "@/api/Auth";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import BreadCrumbs from "@/components/common/ui/BreadCrumbs";

interface FormData {
  identifier: string;
  password: string;
}

export default function Login() {
  const pageRouter = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>();

  const { login } = useAuth();

  const mutate = useMutation({ mutationFn: loginApiCall });

  const onSubmit = (data: FormData) => {
    mutate.mutate(data, {
      onSuccess: (response) => {
        login(response.jwt, response.user);
        toast.success("Login Successfully");
        pageRouter.push("/");
      },
    });
  };

  return (
    <main className="main-login lg:pt-10 pt-24 lg:pb-[100px] pb-10 bg-main-bg-color ">
      <div className="container">
        <div className="login_wrapper flex flex-col">
          <BreadCrumbs category={"login"} notCategory />

          <div className="login_main-wrapper max-w-[600px] w-full m-auto lg:mt-[60px] mt-10 flex flex-col gap-[30px]">
            <form
              onSubmit={handleSubmit(onSubmit)}
              action="#"
              className="login_form bg-white w-full sm:px-[80px] sm:py-[60px] px-4 py-10 flex flex-col items-center gap-[30px]"
            >
              <h1 className="login_form-title text-3xl font-medium tracking-[3px] uppercase">
                Login
              </h1>
              <div className="login_form-input_wrapper flex flex-col gap-[10px] w-full mt-[10px] relative">
                <FormInput
                  label="email"
                  type="email"
                  className="login_form_email-input"
                  register={register("identifier", { required: "Enter Your Email" })}
                  errors={errors}
                  watch={watch}
                />
              </div>
              <div className="login_form-input_wrapper flex flex-col gap-[10px] w-full relative">
                <FormInput
                  label="repeat password"
                  type="password"
                  className="login_form_password-input"
                  register={register("password", {
                    required: "Enter Your Password",
                    minLength: { value: 8, message: "at least 8 characters" },
                    maxLength: { value: 20, message: "maximum 20 characters" },
                  })}
                  errors={errors}
                  watch={watch}
                />
              </div>
              <button
                type="submit"
                className="login_btn w-full h-[60px] bg-disable-color flex items-center justify-center gap-[14px] mt-[10px]"
              >
                <p className="login_btn-title uppercase text-white tracking-[2px] font-semibold text-xl">
                  login
                </p>
                <Icon iconName="i-nextarrow-icon-white" size="text-[24px]" />
              </button>

              <div className="login_account-desc flex flex-col items-center gap-5 mt-[10px]">
                <p className="login_create-account text-sub-text-color text-center">
                  Haven’t an account yet? –{" "}
                  <Link href="/register" className="text-secondary-text-color">
                    Create an account
                  </Link>
                </p>
                <Link href="#" className="login_forgot-password text-secondary-text-color">
                  Forgot password?
                </Link>
              </div>
            </form>

            <div className="login_social-media bg-white w-full sm:px-[60px] sm:py-[30px] px-4 py-6 flex sm:flex-row flex-col justify-between items-center sm:gap-0 gap-4">
              <p className="login_social-media-title tracking-[2px] font-medium uppercase text-sub-text-color">
                sign in by social profiles
              </p>
              <div className="login_social-media_wrapper flex gap-4">
                <Picture
                  className="cursor-pointer"
                  src={"/icons/Icon name=google.svg"}
                  alt={"google"}
                  width={24}
                  height={24}
                />
                <Picture
                  className="cursor-pointer"
                  src={"/icons/Icon name=Facebook.svg"}
                  alt={"Facebook"}
                  width={24}
                  height={24}
                />
                <Picture
                  className="cursor-pointer"
                  src={"/icons/Icon name=twitter.svg"}
                  alt={"twitter"}
                  width={24}
                  height={24}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
